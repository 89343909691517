import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, notification, Row } from "antd";
import { isMobile } from "react-device-detect";
import { AlertCircleOutlineIcon, ArrowBackIcon, ChevronRightIcon, StarIcon, StarOutlineIcon } from "mdi-react";
import store from "../../../store";

// styling
import { css } from "emotion";
import colors from "../../../style/colors";

// hooks
import useOverviewData from "./hooks/useOverviewData";

// actions
import { hideModalPage, showModalPage, showDialog } from "../../../actions/uiActions";

// ui-Components
import InlineSpinner from "../../ui/InlineSpinner";
import DropDown from "../../ui/DropDown";
import Page from "../../ui/Page";
import Button from "../../ui/Button";
import TabView from "../../ui/TabView";
import TabBar from "../../ui/TabBar";
import SearchInput from "../../ui/SearchInput";
import usePageAndBackButtonURL from "../../../hooks/usePageAndBackButtonURL";
import TopBar from "../../ui/TopBar";
import pageNavigator from "../../../utilities/page-navigator";
import StatusBox from "../../ui/StatusBox";
import ListItem from "../../ui/ListItem";
import PunchModal from "./PunchModal";
import punchStatuses from "../../../config/punchStatuses";
import PunchStatus from "./PunchStatus";
import AccessCheck from "../../ui/AccessCheck";

// utilities
import req from "../../../utilities/request-utility";
import highlightMatch from "../../../utilities/highlight-match";

let timeout;
function PunchOverview(props) {
  const { hideModalPage, match, selectedProject, selectedProjectArea, showDialog, showModalPage } = props;

  const [filterData, setFilterData] = useState({
    punchList: "",
    punchOwner: "",
    punchOrigin: "",
    punchLocation: "",
    punchCategory: "",
    area: selectedProjectArea ? (selectedProjectArea.id ? selectedProject.id : "") : "",
    system: "",
    searchterm: "",
    user: "",
  });

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [activeFavoriteIds, setActiveFavoriteIds] = useState([]);
  const [exportLoading, setExporting] = useState(false);

  const { backButtonURL } = usePageAndBackButtonURL(props);
  const {
    openPunches,
    clearedPunches,
    closedPunches,
    favoritedPunches,
    statusCounts,
    getStatusCounts,
    metaData,
    metaDataRefetch,
    getData,
  } = useOverviewData();

  const tabs = [
    {
      title: (
        <PunchStatus
          count={statusCounts.data.open ? statusCounts.data.open : 0}
          offsetBottom={false}
          isLoading={statusCounts.loading}
          status={punchStatuses.open}
        />
      ),
      color: colors.red,
    },
    {
      title: (
        <PunchStatus
          count={statusCounts.data.cleared ? statusCounts.data.cleared : 0}
          offsetBottom={false}
          isLoading={statusCounts.loading}
          status={punchStatuses.cleared}
        />
      ),
      color: colors.vividBlue,
    },
    {
      title: (
        <PunchStatus
          count={statusCounts.data.closed ? statusCounts.data.closed : 0}
          offsetBottom={false}
          isLoading={statusCounts.loading}
          status={punchStatuses.closed}
        />
      ),
      color: colors.green,
    },
    {
      title: (
        <PunchStatus
          count={statusCounts.data.favorites ? statusCounts.data.favorites : 0}
          offsetBottom={false}
          isLoading={statusCounts.loading}
          status={punchStatuses.favorites}
        />
      ),
      color: "#103447",
    },
  ];

  useEffect(() => {
    (async () => {
      const punchActiveTabIndex = localStorage.getItem("punchActiveTabIndex");
      if (punchActiveTabIndex !== null) setActiveTabIndex(parseInt(punchActiveTabIndex));

      const punchFilterData = localStorage.getItem("punchFilterData");
      let initialFilterData = filterData;

      if (punchFilterData) {
        initialFilterData = JSON.parse(punchFilterData);
        setFilterData(JSON.parse(punchFilterData));
      }

      await Promise.all([
        getData({ tabIndex: 0, filterData: initialFilterData }),
        getData({ tabIndex: 1, filterData: initialFilterData }),
        getData({ tabIndex: 2, filterData: initialFilterData }),
        getData({ tabIndex: 3, filterData: initialFilterData }),
      ]);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const favoriteIds = favoritedPunches.data.map((d) => d.id);
    setActiveFavoriteIds(favoriteIds);
  }, [favoritedPunches]);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      refreshData({ clearDataBeforeFetch: true });
    }, 200);
    // eslint-disable-next-line
  }, [filterData]);

  useEffect(() => {
    const resetFilters = () => {
      localStorage.removeItem("punchFilterData");
      localStorage.removeItem("punchActiveTabIndex");
      setActiveTabIndex(0);
      setFilterData({
        punchList: "",
        punchOwner: "",
        punchOrigin: "",
        punchLocation: "",
        punchCategory: "",
        area: "",
        system: "",
        searchterm: "",
        user: "",
      });
    };

    const handlePageHide = () => {
      if (isMobile) return resetFilters();
    };

    const handleVisibilityChange = () => {
      if (isMobile && document.visibilityState === "hidden") {
        resetFilters();
      }
    };

    window.addEventListener("pagehide", handlePageHide);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("pagehide", handlePageHide);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  async function refreshData({ clearDataBeforeFetch = false, tabToUpdate = null, status = null } = {}) {
    getStatusCounts({ filterData, setAsLoading: true });

    if (tabToUpdate !== null && status !== null) {
      switch (tabToUpdate) {
        case 0:
          await Promise.all([
            getData({ tabIndex: 0, refreshData: true, filterData, clearDataBeforeFetch }),
            getData({ tabIndex: 3, refreshData: true, filterData, clearDataBeforeFetch }),
          ]);
          break;
        case 1:
          await Promise.all([
            getData({ tabIndex: 1, refreshData: true, filterData, clearDataBeforeFetch }),
            getData({ tabIndex: 3, refreshData: true, filterData, clearDataBeforeFetch }),
          ]);
          break;
        case 2:
          await Promise.all([
            getData({ tabIndex: 2, refreshData: true, filterData, clearDataBeforeFetch }),
            getData({ tabIndex: 3, refreshData: true, filterData, clearDataBeforeFetch }),
          ]);
          break;
        case 3:
          switch (status) {
            case "Open":
              await Promise.all([
                getData({ tabIndex: 0, refreshData: true, filterData, clearDataBeforeFetch }),
                getData({ tabIndex: 3, refreshData: true, filterData, clearDataBeforeFetch }),
              ]);
              break;
            case "Cleared":
              await Promise.all([
                getData({ tabIndex: 1, refreshData: true, filterData, clearDataBeforeFetch }),
                getData({ tabIndex: 3, refreshData: true, filterData, clearDataBeforeFetch }),
              ]);
              break;
            case "Closed":
              await Promise.all([
                getData({ tabIndex: 2, refreshData: true, filterData, clearDataBeforeFetch }),
                getData({ tabIndex: 3, refreshData: true, filterData, clearDataBeforeFetch }),
              ]);
              break;
          }
          break;
      }
    } else {
      metaDataRefetch();

      await Promise.all([
        getData({ tabIndex: 0, refreshData: true, filterData, clearDataBeforeFetch }),
        getData({ tabIndex: 1, refreshData: true, filterData, clearDataBeforeFetch }),
        getData({ tabIndex: 2, refreshData: true, filterData, clearDataBeforeFetch }),
        getData({ tabIndex: 3, refreshData: true, filterData, clearDataBeforeFetch }),
      ]);
    }
  }

  function handleDropdownChange(e, filterData) {
    const newFilterData = { ...filterData, [e.target.name]: e.target.value ? e.target.value : "" };
    localStorage.setItem("punchFilterData", JSON.stringify(newFilterData));
    setFilterData(newFilterData);
  }

  function handleClearSearchTerm(e, filterData) {
    const newFilterData = {
      ...filterData,
      searchterm: "",
    };

    localStorage.setItem("punchFilterData", JSON.stringify(newFilterData));
    setFilterData(newFilterData);
  }

  const closeModal = () => {
    showDialog({
      title: "Hey there!",
      content: "Your changes will not be saved. Are you sure you want to close the form?",
      primaryActionTitle: "Yes, close the form",
      primaryAction: hideModalPage,
      secondaryActionTitle: "No, don't close the form",
    });
  };

  const exportPunches = async () => {
    setExporting(true);

    try {
      const { data: response } = await req().post(`semcompletion/v2/punches/export`);

      const { appConfig = {} } = store.getState();
      const { apiUrl = "" } = appConfig;

      window.location.href = `${apiUrl}exports/${response.fileName}`;

      setExporting(false);
    } catch (error) {
      setExporting(false);
    }
  };

  const withFilters = useMemo(() => Object.values(filterData).some((value) => value !== ""), [filterData]);

  return (
    <Page className={container()}>
      <TopBar
        title={`Punch (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={
          <ArrowBackIcon
            onClick={() => {
              localStorage.removeItem("punchFilterData");
              localStorage.removeItem("punchActiveTabIndex");
              pageNavigator(backButtonURL, "backward");
            }}
          />
        }
      />
      <AccessCheck pageId={match.params.pageId}>
        {!metaData.loading && !metaData.error && (
          <>
            <div className="header">
              <div className="row">
                {metaData.data.punchLists.length > 1 && (
                  <DropDown
                    allowClear={true}
                    className={filterData.punchList ? "withValue" : ""}
                    name="punchList"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={[...metaData.data.punchLists.map((d) => ({ label: d.name, value: d.id }))]}
                    placeholder="Punch List"
                    style={{ flexBasis: "20%" }}
                    value={filterData.punchList}
                  />
                )}
                <div className="punch-count-container"></div>
                <div style={{ display: "flex", width: "auto" }}>
                  <Button
                    disabled={exportLoading}
                    loading={exportLoading}
                    onClick={exportPunches}
                    style={{ width: "215px", marginRight: 5 }}
                  >
                    Export Report List
                  </Button>
                  <Button
                    onClick={() =>
                      showModalPage({
                        closeCallback: closeModal,
                        content: <PunchModal refreshData={refreshData} />,
                        title: `Create Punch (${selectedProject.number} - ${selectedProject.description})`,
                      })
                    }
                    style={{ width: "215px" }}
                  >
                    Create Punch
                  </Button>
                </div>
              </div>

              <div className="row">
                <SearchInput
                  name="searchterm"
                  onChange={(e) => handleDropdownChange(e, filterData)}
                  onClearSearch={(e) => handleClearSearchTerm(e, filterData)}
                  style={{ width: "100%" }}
                  value={filterData.searchterm}
                />
              </div>
              <Row gutter={[10, 10]}>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <DropDown
                    allowClear={true}
                    className={filterData.punchOwner ? "withValue" : ""}
                    name="punchOwner"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={metaData.data.punchOwners.map((d) => ({ label: d.name, value: d.id }))}
                    placeholder="Owner"
                    style={{ fontSize: 13 }}
                    value={filterData.punchOwner}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <DropDown
                    allowClear={true}
                    className={filterData.punchOrigin ? "withValue" : ""}
                    name="punchOrigin"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={metaData.data.punchOrigins.map((d) => ({ label: d.name, value: d.id }))}
                    placeholder="Origin"
                    style={{ fontSize: 13 }}
                    value={filterData.punchOrigin}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <DropDown
                    allowClear={true}
                    className={filterData.punchCategory ? "withValue" : ""}
                    name="punchCategory"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={metaData.data.punchCategories.map((d) => ({ label: d.name, value: d.id }))}
                    placeholder="Category"
                    style={{ fontSize: 13 }}
                    value={filterData.punchCategory}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <DropDown
                    allowClear={true}
                    className={filterData.punchLocation ? "withValue" : ""}
                    name="punchLocation"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={metaData.data.platformLocations.map((d) => ({ label: d.name, value: d.id }))}
                    placeholder="Location"
                    style={{ fontSize: 13 }}
                    value={filterData.punchLocation}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <DropDown
                    allowClear={true}
                    className={filterData.system ? "withValue" : ""}
                    name="system"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={metaData.data.systems.map((d) => ({ label: d.name, value: d.id }))}
                    placeholder="System"
                    style={{ fontSize: 13 }}
                    value={filterData.system}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <DropDown
                    allowClear={true}
                    className={filterData.area ? "withValue" : ""}
                    name="area"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={metaData.data.areas.map((d) => ({ label: d.name, value: d.id }))}
                    placeholder="Area Code"
                    style={{ fontSize: 13 }}
                    value={filterData.area}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <DropDown
                    allowClear={true}
                    className={filterData.user ? "withValue" : ""}
                    name="user"
                    onChange={(e) => handleDropdownChange(e, filterData)}
                    options={metaData.data.users.map((d) => ({ label: d, value: d }))}
                    placeholder="User"
                    style={{ fontSize: 13 }}
                    value={filterData.user}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8}>
                  <Button
                    disabled={withFilters ? false : true}
                    onClick={() => {
                      localStorage.removeItem("punchFilterData");
                      setFilterData({
                        ...filterData,
                        punchList: "",
                        punchOwner: "",
                        punchOrigin: "",
                        punchCategory: "",
                        punchLocation: "",
                        system: "",
                        area: "",
                        user: "",
                        searchterm: "",
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Col>
              </Row>

              <TabBar
                style={{ margin: "0 -1rem -1rem -1rem" }}
                activeTabIndex={activeTabIndex}
                tabs={tabs.map((tab, index) => ({
                  title: tab.title,
                  color: tab.color,
                  onClick: () => {
                    localStorage.setItem("punchActiveTabIndex", index);
                    setActiveTabIndex(index);
                  },
                }))}
              />
            </div>
            <TabView
              onScrollEnd={(tabIndex) => getData({ tabIndex, filterData })}
              activeTabIndex={activeTabIndex}
              tabs={[
                <Tab
                  activeFavoriteIds={activeFavoriteIds}
                  activeTabIndex={activeTabIndex}
                  filterData={filterData}
                  match={match}
                  punches={openPunches}
                  refreshData={refreshData}
                />,
                <Tab
                  activeFavoriteIds={activeFavoriteIds}
                  activeTabIndex={activeTabIndex}
                  filterData={filterData}
                  match={match}
                  punches={clearedPunches}
                  refreshData={refreshData}
                />,
                <Tab
                  activeFavoriteIds={activeFavoriteIds}
                  activeTabIndex={activeTabIndex}
                  filterData={filterData}
                  match={match}
                  punches={closedPunches}
                  refreshData={refreshData}
                />,
                <Tab
                  activeFavoriteIds={activeFavoriteIds}
                  activeTabIndex={activeTabIndex}
                  filterData={filterData}
                  match={match}
                  punches={favoritedPunches}
                  refreshData={refreshData}
                />,
              ]}
            />
          </>
        )}
      </AccessCheck>
    </Page>
  );
}

const Tab = ({ activeFavoriteIds, activeTabIndex, filterData, match, punches, refreshData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPunchToFavorite, setSelectedPunchToFavorite] = useState(null);

  const { path } = match;
  const { searchterm } = filterData;

  const addPunchToFavorites = async (e, punch) => {
    try {
      setSelectedPunchToFavorite(punch.id);
      setIsLoading(true);

      e.stopPropagation();

      await req().post(`semcompletion/v2/punches/${punch.id}/favorite`);

      await refreshData({ status: punch.status, tabToUpdate: activeTabIndex });

      notification.success({ duration: 7, message: "SUCCESS", description: "Successfully Added to Favorites" });

      setIsLoading(false);
      setSelectedPunchToFavorite(null);
    } catch (error) {
      setIsLoading(false);
      setSelectedPunchToFavorite(null);
      console.log("%c Line:389 🍬 error", "color:#ea7e5c", error);
    }
  };

  const removePunchFromFavorites = async (e, punch) => {
    try {
      setSelectedPunchToFavorite(punch.id);
      setIsLoading(true);

      e.stopPropagation();

      await req().delete(`semcompletion/v2/punches/${punch.id}/favorite`);

      await refreshData({ status: punch.status, tabToUpdate: activeTabIndex });

      notification.success({ duration: 7, message: "SUCCESS", description: "Successfully Removed from Favorites" });

      setIsLoading(false);
      setSelectedPunchToFavorite(null);
    } catch (error) {
      setIsLoading(false);
      setSelectedPunchToFavorite(null);
      console.log("%c Line:414 🥝 error", "color:#6ec1c2", error);
    }
  };

  return (
    <div style={{ padding: "1rem 0" }}>
      {punches.data.length > 0 &&
        !punches.error &&
        punches.data.map((punch, index) => {
          const {
            category: { name: categoryName = "" } = {},
            location: { name: locationName = "" } = {},
            punchOrigin: { name: punchOriginName = "" } = {},
            punchOwner: { name: punchOwnerName = "" } = {},
            system: { name: systemName = "", number: systemNumber = "" } = {},
            area: { code: areaCode, name: areaName = "" } = {},
            workType: { name: workTypeName = "" } = {},
            createdDate,
          } = punch;

          const subTitle = [
            punchOwnerName,
            workTypeName,
            locationName,
            punchOriginName,
            categoryName,
            `${systemNumber} - ${systemName}`,
            `${areaCode} - ${areaName}`,
            createdDate.split("T").shift(),
          ].filter((d) => d.trim() !== "");

          return (
            <ListItem
              onClick={() => void pageNavigator(match.url + "/" + punch.id, "forward")}
              title={highlightMatch(
                `${punch.punchList ? `${punch.punchList.idPrefix}  -` : ""} ${punch.punchId} - ${
                  punch.title ? punch.title : ""
                }`,
                searchterm
              )}
              subTitle={subTitle.join(" | ")}
              iconLeft={
                activeFavoriteIds.includes(punch.id) ? (
                  isLoading && selectedPunchToFavorite && punch.id === selectedPunchToFavorite ? (
                    <InlineSpinner size={18} />
                  ) : (
                    <StarIcon
                      className={starIcon({ status: punch.status })}
                      onClick={(e) => removePunchFromFavorites(e, punch)}
                    />
                  )
                ) : isLoading && selectedPunchToFavorite && punch.id === selectedPunchToFavorite ? (
                  <InlineSpinner size={18} />
                ) : (
                  <StarOutlineIcon onClick={(e) => addPunchToFavorites(e, punch)} />
                )
              }
              iconRight={<ChevronRightIcon />}
              key={punch.id}
              index={index + 1}
              length={punches.data.length}
            />
          );
        })}
      {punches.loading && !punches.error && <InlineSpinner />}
      {!punches.loading && punches.error && <StatusBox />}
      {!punches.loading && !punches.error && punches.data.length === 0 && (
        <StatusBox title="No punches found" icon={<AlertCircleOutlineIcon />} content="  " />
      )}
    </div>
  );
};

const starIcon = ({ status }) => css`
  color: ${status === "Open"
    ? colors.red
    : status === "Cleared"
    ? colors.vividBlue
    : status === "Closed"
    ? colors.green
    : colors.yellow};
`;

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;

      p {
        margin-right: 0.5rem;
        font-size: 0.9rem;
        font-weight: 700;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

const mapStateToProps = (state) => ({
  selectedProject: state.semcompletion.selectedProject,
  selectedProjectArea: state.semcompletion.selectedProjectArea,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PunchOverview);
