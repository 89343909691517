import React from "react";
// styling
import { css } from "emotion";
import colors from "../../../style/colors";
import { AccountAlertIcon, AccountCheckIcon, CheckIcon, StarIcon } from "mdi-react";
import punchStatuses from "../../../config/punchStatuses";
import InlineSpinner from "../../ui/InlineSpinner";

function PunchStatus({ count = null, isLoading = false, status, offsetBottom = true }) {
  const countSection =
    count !== null ? (
      isLoading ? (
        <>
          (<InlineSpinner size={10} style={{ marginTop: "10px" }} />)
        </>
      ) : (
        `(${count})`
      )
    ) : (
      ""
    );

  return (
    <div className={container(offsetBottom) + ` ${status}`}>
      {status === punchStatuses.open && (
        <div style={{ alignItems: "center", display: "flex" }}>
          <AccountAlertIcon /> {status} {countSection}
        </div>
      )}
      {status === punchStatuses.cleared && (
        <div style={{ alignItems: "center", display: "flex" }}>
          <AccountCheckIcon /> {status} {countSection}
        </div>
      )}
      {status === punchStatuses.closed && (
        <div style={{ alignItems: "center", display: "flex" }}>
          <CheckIcon /> {status} {countSection}
        </div>
      )}
      {status === punchStatuses.favorites && (
        <div style={{ alignItems: "center", display: "flex" }}>
          <StarIcon /> {status} {countSection}
        </div>
      )}
    </div>
  );
}

const container = (offsetBottom) => css`
  font-weight: 700;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-bottom: ${offsetBottom ? "-0.25rem" : 0};
  }

  &.Open {
    color: ${colors.red};
  }
  &.Cleared {
    color: ${colors.vividBlue};
  }
  &.Closed {
    color: ${colors.green};
  }
  &.Favorites {
    color: #103447;
  }
`;

export default PunchStatus;
