import React, { useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { AlertDecagramIcon, ArrowBackIcon, ArrowExpandIcon, FilePdfIcon } from "mdi-react";
import { chunk, keyBy } from "lodash";
import nl2br from "react-nl2br";
import { notification } from "antd";
import { isDesktop } from "react-device-detect";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

// styling
import { css } from "emotion";
import colors from "../../../style/colors";

//actions
import store from "../../../store";

// ui-Components
import ButtonRounded from "../../ui/ButtonRounded";
import PDFViewer from "../PDFViewer";
import InlineSpinner from "../../ui/InlineSpinner";
import ListItem from "../../ui/ListItem";
import Page from "../../ui/Page";
import TopBar from "../../ui/TopBar";
import pageNavigator from "../../../utilities/page-navigator";
import { hideDialog, hideModalPage, showDialog, showLightBox, showModalPage } from "../../../actions/uiActions";
import PunchModal from "./PunchModal";
import PunchSingleHeader from "./PunchSingleHeader";
import PunchActivityLog from "./PunchActivityLog";
import ScrollView from "../../ui/ScrollView";
import usePunchSingleData from "./hooks/usePunchSingleData";
import punchStatuses from "../../../config/punchStatuses";
import common from "../../../style/common";
import StatusBox from "../../ui/StatusBox";
import req from "../../../utilities/request-utility";
import checkIsAdmin from "../../../utilities/checkIsAdmin";
import getFileExtension from "../../../utilities/getFileExtension";

const downloadModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  textAlign: "center",
};

function PunchOverview(props) {
  const dispatch = useDispatch();
  const isAdmin = checkIsAdmin();

  const { hideModalPage, lang, selectedProject, showDialog, showModalPage, showLightBox } = props;

  const [exportModal, setExportModal] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [exportUrl, setExportUrl] = useState("");
  const [exportPunchNo, setExportPunchNo] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const { punch, getPunch } = usePunchSingleData(props.match.params.id);

  const {
    area = {},
    punchList = {},
    punchOwner = {},
    punchOrigin = {},
    punchStatusImages = [],
    punchActions = [],
    category = {},
    location = {},
    workType = {},
    tagNumber = "",
    system = {},
    description = "",
    title = {},
    createdBy = "",
  } = punch.data;

  const punchActionsObj = keyBy(punchActions, "action");

  let punchData = [];
  if (!punch.loading && !punch.error) {
    punchData = chunk(
      [
        { label: "Title", value: title },
        { label: "Punch no.", value: punch.data.punchId },
        { label: "Punch list", value: punchList.name },
        { label: "Punch owner", value: punchOwner.name },
        { label: "Punch origin", value: punchOrigin.name },
        { label: "Punch category", value: category.name },
        { label: "Punch location", value: location.name },
        { label: "Issue type", value: workType.name },
        { label: "Tag number / Procedure", value: tagNumber },
        { label: "System", value: `${system.number} - ${system.name}` },
        { label: "Description", value: description },
        { label: "Area Code", value: `${area.code} - ${area.name}` },
      ].filter((d) => d),
      2
    );
  }

  const closeModal = () => {
    showDialog({
      title: lang.heyThere,
      content: lang.closeFormWarningContent,
      primaryActionTitle: lang.yesCloseForm,
      primaryAction: hideModalPage,
      secondaryActionTitle: lang.noDontCloseForm,
    });
  };

  const downloadReport = async (punchId, punchNo) => {
    setExportModal(true);
    setExporting(true);
    setExportPunchNo(punchNo);

    try {
      const { data: response } = await req().post(`semcompletion/v2/punches/report`, { id: punchId });

      const { appConfig = {} } = store.getState();
      const { apiUrl = "" } = appConfig;

      setExportUrl(`${apiUrl}exports/${response.fileName}`);
      setExporting(false);
    } catch (error) {
      setExporting(false);
    }
  };

  const punchImages = useMemo(
    () =>
      punch?.data?.images
        ?.filter((image) => getFileExtension(image.originalImage).extension !== "pdf")
        .map((image) => ({ ...image, ...getFileExtension(image.originalImage) })),
    [punch]
  );
  const punchPdfs = useMemo(
    () =>
      punch?.data?.images
        ?.filter((image) => getFileExtension(image.originalImage).extension === "pdf")
        .map((image) => ({ ...image, ...getFileExtension(image.originalImage) })),
    [punch]
  );

  return (
    <Page backgroundColor={colors.white} className={container()}>
      <TopBar
        title={`Punch (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={
          <ArrowBackIcon
            onClick={() => {
              props.location.state && props.location.state.pageId === "1115"
                ? props.history.goBack()
                : pageNavigator("BACK", "backward");
            }}
          />
        }
        actionRight={
          <>
            {!punch.loading && !punch.error && (
              <button
                small="true"
                className="download-button"
                disabled={exporting}
                onClick={() => downloadReport(punch.data.id, punch.data.punchId)}
              >
                {exporting ? "Downloading..." : "Download"}
              </button>
            )}
            {isAdmin && !punch.loading && !punch.error && punch.data.status === punchStatuses.open ? (
              <button
                small="true"
                className="delete-button"
                onClick={() => {
                  const actions = [
                    {
                      title: "Delete",
                      action: async () => {
                        dispatch(hideDialog());
                        await req().delete(`semcompletion/v2/punches/${punch.data.id}`);
                        pageNavigator("BACK", "backward");
                        notification.success({
                          duration: 7,
                          message: "SUCCESS",
                          description: "Punch has been deleted successfully",
                        });
                      },
                    },
                  ];

                  const dialog = {
                    title: "Hey there!",
                    content: "What do you want to do?",
                    secondaryActionTitle: "Cancel",
                    actions,
                  };
                  dispatch(showDialog(dialog));
                }}
              >
                Delete
              </button>
            ) : null}
            {!punch.loading && !punch.error && punch.data.status === punchStatuses.open ? (
              <button
                small="true"
                className="edit-button"
                onClick={() =>
                  showModalPage({
                    closeCallback: closeModal,
                    content: (
                      <PunchModal
                        initialFormData={{
                          id: punch.data.id,
                          area: area.id,
                          punchId: punch.data.punchId,
                          punchList: punchList.id,
                          punchOwner: punchOwner.id,
                          punchOrigin: punchOrigin.id,
                          punchCategory: category.id,
                          platformLocation: location.id ? location.id : "",
                          platformLocationText: location,
                          workType: workType.id,
                          tagNumber: tagNumber,
                          system: system.id,
                          description: description,
                          title: title,
                          name: createdBy,
                          images: punch.data.images,
                        }}
                        editMode={true}
                        refreshData={getPunch}
                      />
                    ),
                    title: `Edit Punch (${selectedProject.number} - ${selectedProject.description})`,
                  })
                }
              >
                Edit
              </button>
            ) : null}
          </>
        }
      />

      {punch.loading && <InlineSpinner />}

      {!punch.loading && punch.error && (
        <StatusBox
          style={{ paddingTop: "2rem" }}
          title="Punch not found"
          content="Punch not found"
          icon={<AlertDecagramIcon />}
        />
      )}

      {!punch.loading && !punch.error && (
        <>
          <PunchSingleHeader punch={punch.data} punchActions={punchActionsObj} getPunch={getPunch} />

          <ScrollView style={{ padding: "1rem 0" }}>
            {/* Punch data */}
            <div className="row">
              <div className="col-100">
                <h2>Punch data</h2>
              </div>
            </div>
            {punchData.map((row, index) => {
              return (
                <div className="row" key={`punch-data-row-${index}`}>
                  {row.map((column) => (
                    <div className="col-50" key={column.label}>
                      <p>
                        <span className="label">{column.label}</span>
                        {column.label === "Description" ? <p>{nl2br(column.value)}</p> : column.value}
                      </p>
                    </div>
                  ))}
                </div>
              );
            })}

            {punchPdfs.length !== 0 && (
              <div className="row">
                <div className="col-100">
                  <span className="label">PDFs</span>
                  {punchPdfs.map((pdf) => {
                    return (
                      <ListItem
                        clickable={true}
                        iconRight={<FilePdfIcon />}
                        key={pdf.originalImage}
                        onClick={(e) =>
                          showModalPage({
                            closeCallback: hideModalPage,
                            content: <PDFViewer file={pdf.originalImage} />,
                            title: pdf.displayFileName,
                          })
                        }
                        title={pdf.displayFileName}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            <div className="image-container">
              {punchImages.map((image) => (
                <div
                  className="image-wrapper"
                  key={image.image}
                  style={{
                    backgroundImage: `url("${image.image}")`,
                  }}
                >
                  <button className="fullscreen-toggle">
                    <ArrowExpandIcon
                      onClick={() =>
                        showLightBox({
                          images: [
                            {
                              baseURL: "",
                              image: image.originalImage,
                            },
                          ],
                        })
                      }
                    />
                  </button>
                </div>
              ))}
            </div>

            <div className="seperator" style={{ marginBottom: "1rem" }} />

            {/* Activity log */}
            <div className="row">
              <div className="col-50">
                <h2>Activity Log</h2>
              </div>
              <div className="col-50" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <ButtonRounded secondary={true} small={true} onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? "Hide" : "Show"} all details
                </ButtonRounded>
              </div>
            </div>

            <div className="row">
              <div className="col-100">
                <PunchActivityLog
                  activityLog={punch.data.activityLog.map((d) => ({
                    ...d,
                    punchStatusImages: punchStatusImages.filter((e) => e.punchActivityLogId === d.id),
                  }))}
                  isAllExpanded={isExpanded}
                />
              </div>
            </div>
          </ScrollView>
        </>
      )}

      <Modal open={exportModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={downloadModalStyle}>
          <Typography
            id="modal-modal-title"
            style={{ fontFamily: common.fontStack, fontWeight: "bold", fontSize: 18 }}
            variant="h6"
            component="h6"
          >
            {exporting ? "Generating PDF File" : "Download PDF File"}
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{ fontFamily: common.fontStack, fontWeight: "bold", fontSize: 14 }}
            variant="h6"
            component="h6"
            sx={{ mt: 5 }}
          >
            Punch No. {exportPunchNo}
          </Typography>
          <Typography id="modal-modal-description" style={{ fontFamily: common.fontStack, fontSize: 14 }} sx={{ mb: 3 }}>
            {exporting
              ? "Please wait while we process your document. This may take a few moments."
              : "Your document is now ready. Click the button below to download."}
          </Typography>

          {exporting && <CircularProgress color="primary" />}
          <div style={{ display: "block", marginTop: 10 }}>
            <Button
              style={{ backgroundColor: "#F4E1DA", color: "#C86746", fontWeight: "bold", textTransform: "capitalize" }}
              onClick={() => {
                setExportModal(false);
                setExporting(false);
              }}
              variant="contained"
              sx={{ mr: exporting ? 0 : 3 }}
            >
              {exporting ? "Cancel" : "Close"}
            </Button>
            {!exporting && (
              <Button
                href={exportUrl}
                target="_blank"
                variant="contained"
                style={{ backgroundColor: "#E7EBED", color: "#103447", fontWeight: "bold", textTransform: "capitalize" }}
              >
                Download
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </Page>
  );
}

const container = () => css`
  .seperator {
    border-bottom: 1px ${colors.midGrey} solid;
  }

  .download-button,
  .edit-button,
  .delete-button {
    display: block;
    height: ${common.topBarHeight}px;
    line-height: ${common.topBarHeight}px;
    padding: 0 10px;
    background-color: transparent;
    font-size: 1rem;
    color: ${colors.white};
    border: 0px;

    svg {
      color: ${colors.white};
      width: 1rem;
      height: 1rem;
      padding: 0;
      margin-bottom: -2px;
      margin-right: 0.15rem;
    }
  }

  .image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 2rem auto;
    padding: 0 0.5rem;

    .image-wrapper {
      flex: 1 1 46%;
      min-width: 300px;
      padding-bottom: 40%;
      border: 1px ${colors.midGrey} solid;
      border-radius: 5px;
      background-size: cover;
      background-position: center;
      margin: 0.5rem;
      position: relative;

      button.fullscreen-toggle {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: ${colors.white};
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        border: 1px ${colors.lightGrey} solid;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.9;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        svg {
          color: ${colors.darkGrey};
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    p {
      color: ${colors.black};
    }

    span.label {
      font-weight: 700;
      color: ${colors.black};

      &:after {
        content: ": ";
      }
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  selectedProject: state.semcompletion.selectedProject,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  showLightBox: bindActionCreators(showLightBox, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PunchOverview);
